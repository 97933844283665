<template>
  <div class="flex items-center">
    <img
      v-if="item.id === 'bike'"
      class="w-7 h-7"
      src="@/assets/img/vehicle-icons/scooter_light.svg"
      alt="bike"
    />
    <i v-else :class="item.icon_class" :style="'color:' + item.color"></i>
    <div class="ml-4">
      <span class="block grid-text ">{{ item.title }}</span>
      <span class="block grid-sub-text" :style="`max-width : ${width}px;`">{{
        item.subTitle
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: false,
    },
    width: {
      required: false,
    },
  },
}
</script>
