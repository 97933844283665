<template>
  <section class="bg-white rounded-md shadow">
    <div class="relative">
      <div class=" flex items-center w-full h-full place-content-center py-5">
        <div class="">
          <span class="block cost-total-title"
            >{{ rentalDetail.invoice.currency.symbol }}
            {{ parseFloat(rentalDetail.total_cost).toFixed(2) }}</span
          >
          <span class="block cost-sub-title"> {{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.rentCost'
                    )
                  }}</span>
        </div>
      </div>
      <template v-if="isRefunded">
        <div class="absolute" style="right: 5%; top: 5%">
          <img src="@/assets/img/payment/refunded-seal.png" class="w-32 h-32" />
        </div>
      </template>
      <!-- <img
        src="@/assets/img/bg/group-43.png"
        class="w-full cost-bg"
        resizeMode="contain"
      /> -->
    </div>
    <div v-if="rentalDetail !== 'REQUESTED'">
      <div v-if="isRentTimeExtended">
        <div class="grid grid-cols-12 flex gap-2">
          <div
            class="col-span-6 bg-gray-100 text-gray-900 p-5 rental-status-detail"
          >
            <div class="flex items-center justify-center">
              <div class="mr-2"><i class="fas fa-stopwatch"></i></div>
              <div>Rental time</div>
            </div>
            <div class="flex items-center justify-center mt-2">
              <div class="text-2xl md:text-xl xl:text-3xl font-bold">
                {{ getRentalTime }}
              </div>
            </div>
          </div>
          <div
            class="col-span-6  text-white p-5 rental-status-detail"
            :class="
              isRentFinished
                ? 'bg-gray-100 text-gray-900'
                : 'bg-green-600 text-oWhite'
            "
          >
            <div class="flex items-center justify-center">
              <div class="mr-2"><i class="fas fa-stopwatch"></i></div>
              <div>Extended Time</div>
            </div>
            <div class="flex items-center justify-center mt-2">
              <div class="text-2xl md:text-xl xl:text-3xl font-bold">
                {{ getExtendedTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class=" p-5 rental-status-detail"
          :class="
            isRentFinished
              ? 'bg-gray-100 text-gray-900'
              : 'bg-green-600 text-oWhite'
          "
        >
          <div class="flex items-center justify-center">
            <div class="mr-2"><i class="fas fa-stopwatch"></i></div>
            <div>
              {{ isRentFinished ? `${$t('components.vehicleRentalsManagement.details.rentCostSection.rentalTime')}` : `${$t('components.vehicleRentalsManagement.details.rentCostSection.rentingTimeLeft')}` }}

            </div>
          </div>
          <div class="flex items-center justify-center mt-2">
            <div class="text-3xl font-bold">
              {{ isRentFinished ? getRentalTime : rentTimeLeft }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cost-view-box">
      <div class="px-6 ">
        <div class="cost-list">
          <div class="col-w-8">
            <p :style="'color:#000000'">
             {{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.rentalDuration'
                    )
                  }}({{ getRentalTime }})
            </p>
            <p class="text-sm text-gray-500">
             {{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.including'
                    )
                  }} {{ rentalDetail.tax_percentage }}% {{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.vat'
                    )
                  }}  =
              {{ getFormattedPrice(rentalDetail.tax_amount) }}
            </p>
          </div>
          <div class="justify-end text-right col-w-2">
            <span :style="'color:#000000'">{{
              getFormattedPrice(rentalDetail.net_cost)
            }}</span>
          </div>
        </div>
      </div>
      <div class="px-6" v-if="rentalDetail.is_time_extended">
        <div class="cost-list">
          <div class="col-w-8">
            <p :style="'color:#000000'">
              Extended Duration ({{ getExtendedTime }})
            </p>
            <p class="text-sm text-gray-500">
              Including {{ rentalDetail.tax_percentage }}% VAT =
              {{
                getFormattedPrice(rentalDetail.rent_time_extension_tax_amount)
              }}
            </p>
          </div>
          <div class="justify-end text-right col-w-2">
            <span :style="'color:#000000'">{{
              getFormattedPrice(rentalDetail.net_rent_time_extension_cost)
            }}</span>
          </div>
        </div>
      </div>
      <div class="px-6">
        <div class="cost-list">
          <div class="col-w-8">
            <span :style="'color:#3ebc30'">{{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.discount'
                    )
                  }}</span>
          </div>
          <div class="justify-end text-right col-w-2">
            <span :style="'color:#3ebc30'">{{
              getFormattedPrice(rentalDetail.discount)
            }}</span>
          </div>
        </div>
      </div>
      <div class="px-6">
        <div class="cost-list">
          <div class="col-w-8">
            <span :style="'color:#000000'">{{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.subTotal'
                    )
                  }}</span>
          </div>
          <div class="justify-end text-right col-w-2">
            <span :style="'color:#000000'">{{
              getFormattedPrice(rentalDetail.invoice.amount)
            }}</span>
          </div>
        </div>
      </div>
      <div class="px-6">
        <div class="cost-list no-border ">
          <p class="font-bold text-lg">{{
                    $t(
                      'components.vehicleRentalsManagement.details.rentCostSection.payments'
                    )
                  }}</p>
        </div>
      </div>

      <div class="px-6">
        <div
          class="cost-list no-border"
          v-for="(paymentRecord,
          paymentRecordIndex) in rentalDetail.payment_records"
          :key="paymentRecordIndex"
        >
          <div class="col-w-7">
            <p>{{ paymentRecord.payment_source_name }}</p>
          </div>
          <div class="col-w-3" style="text-align: end">
            <p>
              <span v-if="paymentRecord.is_for_refund">-</span>
              {{ getFormattedPrice(paymentRecord.amount) }}
            </p>
            <p v-if="paymentRecord.is_for_refund" class="text-sm text-gray-500">
              Refunded
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="rental-status-detail-group mt-2">
      <div class="p-5 rental-status-detail">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Requested</div>
            <div class="text-sm text-color-sub">
              Rent ID:
              <span class="">
                #{{ rentalDetail.id.substr(rentalDetail.id.length - 5) }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.requested_at) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'REQUESTED'"
        :message="`Waiting for Approval`"
      />

      <div class="p-5 rental-status-detail" v-if="rentalDetail.accepted_at">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Request Accepted</div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.accepted_at) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'ACCEPTED'"
        :message="`Waiting for Delivery`"
      />

      <div class="p-5 rental-status-detail" v-if="rentalDetail.delivered_at">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Vehicle Delivered</div>
            <div class="text-sm text-color-sub">
              Vehicle QR:
              <router-link
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: rentalDetail.vehicle.id },
                }"
              >
                <span class="text-blue-500 text-sm">
                  {{ rentalDetail.vehicle.qr_code }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.delivered_at) }}
            </div>
          </div>
        </div>
      </div>

      <div class="p-5 rental-status-detail" v-if="detail">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Started</div>
            <div class="text-sm text-color-sub">
              Trip ID:
              <router-link
                :to="{
                  name: 'ViewTrip',
                  params: { id: detail.id },
                }"
              >
                <span class="text-blue-500 text-sm">
                  #{{ detail.id.substr(detail.id.length - 5) }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(detail.pick_up_time) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'DELIVERED'"
        :message="`Waiting for Completion`"
      />

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'TRIP_COMPLETED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Completed</div>
            <div class="text-sm text-color-sub">
              {{ rentalDetail.delivery_address }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'REJECTED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-times-circle text-3xl text-oRed"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base text-oRed">Request Rejected</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.reject_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.rejected_at) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.cancel_requested_at"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-exclamation-triangle text-2xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Cancellation Request by Rider</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.cancel_request_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.cancel_requested_at) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'CANCELLED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-exclamation-triangle text-2xl text-oRed"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base text-oRed">Rent Cancelled</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.cancel_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.cancelled_at) }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import moment from 'moment'

import { getFormattedDateTime as getFormattedDateTimeBase } from '@/utils/datetime'
// import RippleBlock from './RippleBlock.vue'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils'
import { EventBus } from '@/utils/EventBus'
export default {
  components: {
    // RippleBlock,
  },
  props: {
    detail: {
      required: false,
      default: null,
    },
    rentalRawData: {
      required: false,
      default: null,
    },
    symbol: {
      required: false,
    },
  },
  mounted() {
    if (
      this.getRentStatus === 'CANCELLED' ||
      this.getRentStatus === 'REJECTED' ||
      this.getRentStatus === 'TRIP_COMPLETED'
    ) {
      console.log('hit')
    } else if (
      this.getRentStatus !== 'DELIVERED' &&
      this.getRentStatus !== 'TRIP_STARTED'
    ) {
      this.rentTimeLeft = '--'
    } else {
      this.updateTimeLeft()

      const self = this

      this.timeLeftInterval = setInterval(() => {
        self.updateTimeLeft()
      }, 1000 * 1)

      if (this.isRentTimeExtended) {
        this.extendedTimeInterval = setInterval(() => {
          self.updateExtendedTime()
        }, 1000 * 1)
      }
    }
  },
  watch: {
    rentalRawData: {
      immediate: true,
      deep: true,
      handler(data) {
        this.rentalDetail = data
        if (data && data.is_time_extended) {
          this.extendedTime = data.extended_duration_in_seconds
        }
        if (data) {
          if (data.status === 'TRIP_COMPLETED' || data.status === 'CANCELLED') {
            clearInterval(this.timeLeftInterval)
            clearInterval(this.extendedTimeInterval)
          }
        }
      },
    },
  },
  beforeDestroy() {
    if (this.timeLeftInterval) {
      clearInterval(this.timeLeftInterval)
    }
    if (this.extendedTimeInterval) {
      clearInterval(this.extendedTimeInterval)
    }
  },
  computed: {
    isRefunded() {
      return this.rentalDetail.is_refunded
    },
    isRentTimeExtended() {
      return this.rentalDetail.is_time_extended
    },
    isRentFinished() {
      if (
        this.getRentStatus === 'TRIP_COMPLETED' ||
        this.getRentStatus === 'CANCELLED'
      ) {
        return true
      } else {
        return false
      }
    },
    getRentalTime() {
      return milisecToDHMSConverter(
        this.rentalDetail.duration_in_seconds * 1000
      )
    },
    getExtendedTime() {
      return milisecToDHMSConverter(this.extendedTime * 1000)
    },
    getRentStatus() {
      return this.rentalDetail.status
    },
  },
  data() {
    return {
      rentalDetail: {},
      onTripDurationStr: null,
      rentTimeLeft: '--',
      extendedTime: 0,
    }
  },
  methods: {
    getFormattedDateTime(...args) {
      return getFormattedDateTimeBase(...args)
    },
    updateTimeLeft() {
      const mobj = moment.duration(
        moment(this.rentalDetail.delivered_at)
          .add(this.rentalDetail.duration, 'h')
          .diff(moment())
      )

      const d = mobj.days()
      const h = mobj.hours()
      const m = mobj.minutes()
      const s = mobj.seconds()

      if (d > 0) {
        this.rentTimeLeft = `${d} d ${h} h ${m} m`
      } else if (h > 0) {
        this.rentTimeLeft = `${h} h ${m} m ${s} s`
      } else if (m > 0) {
        this.rentTimeLeft = `${m} m ${s} s`
      } else if (s > 0) {
        this.rentTimeLeft = `${s} s`
      } else {
        this.rentTimeLeft = milisecToDHMSConverter(
          this.rentalDetail.duration_in_seconds * 1000
        )
        clearInterval(this.timeLeftInterval)
        EventBus.$emit('refresh-rent')
      }
    },
    updateExtendedTime() {
      this.extendedTime = this.extendedTime + 1
    },
    getFormattedPrice(price) {
      if (price !== null) {
        return (
          this.rentalDetail?.invoice?.currency?.symbol +
          ' ' +
          parseFloat(price).toFixed(2)
        )
      } else {
        return '--'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-fig-green {
  background-color: #27b06e !important;
}

.fg-fig-green {
  color: #27b06e !important;
}

.rental-status-detail-group .rental-status-detail:not(:first-child) {
  border-top: 1px solid #f2f2f2;
}

.cost-view-box {
  @media (min-width: 768px) {
    min-height: 400px;
  }
}
</style>
