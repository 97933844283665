<template>
  <div class="py-3 px-8 mb-2 bg-white rounded-md shadow">
    <p class="text-lg font-extrabold"> {{
              $t('components.vehicleRentalsManagement.details.tags.tags')
            }}</p>
    <OtoTag :entity-id="id" :entity-type="'vehiclerental'" />
  </div>
</template>

<script>
import OtoTag from '@/composites/tag/OtoTag.vue'
export default {
  props: ['id'],
  components: {
    OtoTag,
  },
  setup() {},
}
</script>
