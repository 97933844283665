<template>
  <div class="mb-2 mt-3">
    <section class="w-full px-6 py-3 bg-white rounded-md shadow text-center">
      <div class="flex items-center justify-center">
        <div class="tab-items">
          <div
            v-for="tab in tabs"
            :key="`tab-item-${tab.id}`"
            :class="getTabClass(tab.id)"
            v-text="tab.text"
            @click="onClickTab(tab.id)"
          />
        </div>
      </div>
    </section>

    <TimelineGroup
      v-if="activeTabId === 'rental'"
      :isLoading="isLoading"
      :logs="logs"
      timeline-type="rental"
    />
    <IotLogTimeline
      v-if="activeTabId === 'iot'"
      :endpoint="getIotlogEndpoint"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
import IotLogTimeline from '@/composites/trip/details/blocks/timeline/IotLogTimeline.vue'

export default {
  name: 'TimelineHOC',

  props: {
    rentalDetail: {
      type: Object,
      required: true,
    },
  },

  components: {
    TimelineGroup,
    IotLogTimeline,
  },

  data() {
    return {
      isLoading: false,
      logs: [],
      tabs: [
        { id: 'rental', text: this.$t(
            'components.vehicleRentalsManagement.details.tripTimeline.tab.trip'
          ),},
        { id: 'iot', text: this.$t(
            'components.vehicleRentalsManagement.details.tripTimeline.tab.iot'
          ), },
      ],
      activeTabId: 'rental',
    }
  },

  async created() {
    await this.fetchLogs(this.activeTabId)
  },

  computed: {
    getIotlogEndpoint() {
      return `${useEndpoints.vehicle.iotLogs.all(
        this.rentalDetail.vehicle.id
      )}?start_date=${this.getUTCDate(
        this.rentalDetail.trip.pick_up_time
      )}&end_date=${this.getUTCDate(
        this.rentalDetail.trip.drop_off_time
      )}&start_time=${this.getUTCTime(
        this.rentalDetail.trip.pick_up_time
      )}&end_time=${this.getUTCTime(this.rentalDetail.trip.drop_off_time)}`
    },
  },

  methods: {
    async fetchLogs(type) {
      this.isLoading = true
      let url
      if (type === 'rental') {
        url = useEndpoints.vehicleRental.rentalRequestLogs(this.rentalDetail.id)
      }
      await this.$http
        .get(url)
        .then((res) => {
          console.log('fetchLogs res = ', res.data['data'])
          this.logs = res.data['data']
        })
        .catch((err) => {
          console.error('fetchLogs err = ', err, err.response.data)
        })
        .finally(() => (this.isLoading = false))
    },

    getTabClass(tabId) {
      return {
        'tab-item': true,
        'tab-item--active': this.activeTabId === tabId,
      }
    },

    async onClickTab(tabId) {
      this.activeTabId = tabId
      if (this.activeTabId === 'rental') {
        await this.fetchLogs(this.activeTabId)
      }
    },
    getStandardNumber(number) {
      if (number < 10) return `0${number}`
      else return number
    },
    getUTCDate(zString) {
      const zuluDate = zString ? new Date(zString) : new Date()
      return `${zuluDate.getUTCFullYear()}-${this.getStandardNumber(
        zuluDate.getUTCMonth() + 1
      )}-${this.getStandardNumber(zuluDate.getUTCDate())}`
    },
    getUTCTime(zString) {
      const zuluDate = zString ? new Date(zString) : new Date()
      return `${this.getStandardNumber(
        zuluDate.getUTCHours()
      )}:${this.getStandardNumber(
        zuluDate.getUTCMinutes()
      )}:${this.getStandardNumber(zuluDate.getUTCSeconds())}`
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-section {
  min-height: 4rem;
  @apply grid items-center w-full px-6 py-1 bg-white rounded-md shadow;
  @apply grid-cols-1 md:grid-cols-3;
}

.tab-items {
  @apply flex max-w-sm rounded-full bg-gray-200 py-1 px-2 gap-2 transition-all duration-200 ease-in-out;

  .tab-item {
    min-width: 80px;
    @apply flex items-center justify-center text-center text-xs cursor-pointer py-2 px-2 font-medium text-gray-700 rounded-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900;

    &--active {
      @apply bg-black text-white;
      &:hover {
        @apply bg-black text-white;
      }
    }
  }
}
</style>
